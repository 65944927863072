import lang from "../../resources/settings/language";

import React, {Component} from 'react';
import './styles.css'

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.loadPage = this.loadPage.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.state = {showMenuItems: false};
    }

    loadPage(hash) {
        document.location = '#' + hash;
        this.hideMenu();
    }
    hideMenu(){
        this.setState({showMenuItems:false})
    }
    showMenu(){
        this.setState({showMenuItems:true})
    }
    render() {
        return (
            <div className={"menu_nav"}>
                <ul>
                <li onClick={() => this.loadPage('')}><a><span>{lang.mainMenu.home}</span></a></li>

                    <li  style={{position: 'relative'}} onMouseOver={this.showMenu} onMouseOut={this.hideMenu}><a className={this.state.showMenuItems ?"selected-menu-item":''}><span>{lang.mainMenu.lab_results}</span></a>
                        <ul style={{display: this.state.showMenuItems ? 'block' : 'none'}} className={"main-menu "}>
                            <li className={"menu-item"} onClick={() => this.loadPage('resuls-login')}><span>
                                <i className={"fas fa-vials"}/>{lang.mainMenu.patientLabResults}</span>
                            </li>
                            <li className={"menu-item"} onClick={() => this.loadPage('resuls-login')}><span>
                                <i className={"fas fa-vials"}/>{lang.mainMenu.labsLabResults}</span>
                            </li>
                            <li className={"menu-item"} onClick={() => this.loadPage('resuls-login')}><span>
                                <i className="fas fa-user-md"/>{lang.mainMenu.doctorLabResults}</span>
                            </li>
                        </ul>
                    </li>
                    <li onClick={() => this.loadPage('contact')}><a><span>{lang.mainMenu.contactUs}</span></a></li>

                </ul>
            </div>
        )
    }
}

export default MainMenu;
