import React, {Component} from 'react';
import aboutUs from '../../resources/data/aboutus'
import home from '../../resources/data/home'
import contact from '../../resources/data/contact'
import info from '../../resources/data/info'
import nbTests from '../../resources/data/info/none-blood-tests';
import fasting from '../../resources/data/info/fasting';
import foodBeforeTest from '../../resources/data/info/foodBeforeTest';
import fastingFAQs from '../../resources/data/info/fastingFAQs';
import gettingReady from '../../resources/data/info/gettingReady'
import insurance from '../../resources/data/insurance'


import labResults from '../../resources/data/labResults'
import "./styles.css"
import ResultsLogin from "../ResultsLogin";
import RightBanner from '../RightBanner';

import ContactPage from '../Pages/contact';

class MainPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {content: {__html: 'Test'}}
        this.loadPage = this.loadPage.bind(this);
        this.switchHomeBackground = this.switchHomeBackground.bind(this);
        this.backgroundTimer = null;
        this.backgroundCounter = 1;
        this.pages = {
            home: home,
            aboutUs: aboutUs,
            labResults: labResults,
            info: info,
            contactUs: contact,
            nbTests: nbTests,
            fasting: fasting,
            foodBeforeTest: foodBeforeTest,
            fastingFAQs: fastingFAQs,
            gettingReady: gettingReady,
            insurance: insurance
        }
        window.contentManager = this;

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({context: nextContext.context})
    }

    componentDidMount() {
        this.loadPage('home');
    }

    switchHomeBackground() {

        let carousel = document.getElementById('home-carousel');
        if (!carousel){
            return;
        }
        this.backgroundCounter = (++this.backgroundCounter % 4);
        carousel.className = 'background-' + this.backgroundCounter;
        console.log(carousel.className);
    }

    loadPage(pageName) {

        if (pageName === 'home' || !pageName) {
            this.backgroundTimer = setInterval(this.switchHomeBackground, 3000);
        } else {
            clearInterval(this.backgroundTimer);
        }
        if (pageName === 'labResults') {
            this.setState({content: 'labResults'});
            return;
        }
        if (pageName === 'contactUs') {
            this.setState({content: 'contactPage'});
            return;
        }
        let pageContent = this.pages[pageName];
        let pageBody = '';
        let introduction = '';
        let pageTitle = '<div id="page-top" class="page-title">' + pageContent.title + '</div>';
        let titles = [];
        let backToTop = '<div class="back-to-top"><a href=#page-top g1' + 'title="عودة إلى بداية الصفحة"><i class="fas fa-arrow-up"></i></a></div>';

        if (pageContent.introduction) {
            introduction = '<div class="page-introduction">' + pageContent.introduction + '</div>';
        }
        if (Array.isArray(pageContent.details)) {
            for (let index in pageContent.details) {
                let section = pageContent.details[index];
                if (section.linkTitle) {
                    titles.push('<li><a href="#p_' + index + '">' + section.linkTitle + '</a></li>');
                }

                let details = '<div class="details-paragraph">' + (section.details || '') + '</div>';//.replace(new RegExp('\n', 'g'), '<br/>')

                pageBody += '<div >' + (section.title ? '<div class="paragraph-title">' + section.title + '</div>' : '') + '<div id=' + 'p_' + index + ' class=paragraph-details>' + details + '</div></div>';
                if (pageContent.addLinks) {
                    pageBody += backToTop;
                }
            }

            if (pageContent.addLinks) {
                return {content: {__html: pageTitle + introduction + '<ul class="shortcuts">' + titles.join(' ') + '</ul>' + pageBody}}
            } else {
                return {content: {__html: pageTitle + pageBody}}
            }
        } else {
            return {content: {__html: pageContent.detail}}
        }

    }

    render() {

        if (this.props.page === 'labResults') {
            return (
                <div style={{paddingLeft: 0}}>
                    <table className={'content'} border="0">
                        <tr>
                            <td valign="top"><RightBanner/></td>
                            <td valign="top" className={"center-panel"}><ResultsLogin/></td>
                        </tr>
                    </table>
                </div>
            )
        }
        let htmlContent;

        if (this.props.page !== 'contact') {
             htmlContent = this.loadPage(this.props.page);
        }

        console.log(htmlContent);
        return (

            <div style={{paddingTop: 0, paddingLeft: 0}}>
                <table className={'content'} border="0">
                    <tr>
                        <td valign="top"><RightBanner/></td>
                        <td valign="top" className={"center-panel"}>
                            {
                                this.props.page === 'contact' ? <ContactPage/> :
                                    <div dangerouslySetInnerHTML={htmlContent.content}/>
                            }
                        </td>
                    </tr>
                </table>
            </div>
        )
    }
}

export default MainPanel;
