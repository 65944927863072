import React, {Component} from 'react';
import contactData from '../../resources/data/contact';
import "./styles.css"


const ContactPage = props => (
    <div style={{width:'100%'}}>
        <div>
            <div id="page-top" className="page-title">اتصل بنا</div>
            <div>
                <div id="p_0" className="paragraph-details">
                    <div className="details-paragraph">
                        <div className="paragraph">
                            <span className="title">الفرع 1 </span>
                            <p> </p>
                            <a className={"map-area"} target="_blank"
                               href={"https://www.google.com/maps/place/Habash+Medical+Lab/@33.5139373,36.2940645,15z/data=!4m2!3m1!1s0x0:0xeb245bf84e3c7eff?sa=X&ved=2ahUKEwjKkOy-yarhAhVG0aYKHZcPDYEQ_BIwD3oECA0QBg"}>
                                <img height="160" className="map"
                                     src="https://www.google.com/maps/vt/data=zExR1-LpBEi2JKIIien6ZUYjG9yv0O1AujkOMxbQrGlc-l7fRrEF3pmXYPU4BRR2_-N0LA9410Mg8rKToMqWrQrSC0QREW6nlpCM7M9bMATKvqRroc8tzNE4CMB0IrgHt3PXaksnvLcY1uyN"
                                />
                            </a>
                            <p>جسر فيكتوريا - مجمع فيكتوريا&nbsp; التجاري - فوق المصرف التجاري رقم 9</p>
                            <p><span class="info-title"><i className="fas fa-phone"></i> </span> 2238766-22960290&nbsp;</p>
                            <p><span className="info-title"><i className="fas fa-mobile-alt"></i> </span>0993767431</p>
                            <p><span className="info-title"><i className="far fa-envelope"></i> </span><a href={"mailto:support@habashlabs.com"}>support@habashlabs.com</a></p>

                            <p>دوام متواصل: </p>
                            <p>صيفاً من الساعة ٨ صباحاً الى الساعة ٧ مساءًً</p>
                            <p>شتاءً&nbsp;من الساعة ٨ صباحاً الى الساعة ٦ مساءًً</p>

                        </div>
                        <hr className={'red-hr vertical-separator'}/>
                        <div className="paragraph">
                        <span className="title"> الفرع 2</span>
                            <p> </p>
                        <a className={"map-area"} target="_blank"
                           href={"https://www.google.com/maps/place/Omar+Habash+Medical+Lab/@33.5235789,36.2872982,15z/data=!4m2!3m1!1s0x0:0x6e3ebd5d1fd27fe8?sa=X&ved=2ahUKEwi12PSqnc_hAhVSLFAKHb7NA_kQ_BIwD3oECA0QBg"}>
                            <div  className="map map-2"/>
                        </a>
                        <div className="paragraph">
                            <p>الجسر الأبيض - أول نزلة الطلياني - جادة حبوباتي</p>
                            <p><span class="info-title"><i className="fas fa-phone"></i> </span> 3348664-3348665</p>
                            <p><span className="info-title"><i className="fas fa-mobile-alt"></i> </span>0993863037</p>
                            <p><span className="info-title"><i className="far fa-envelope"></i> </span><a href={"mailto:dr_omarh_lab@outlook.com"}>dr_omarh_lab@outlook.com</a></p>
                            <p>دوام متواصل: </p>
                            <p>صيفاً من الساعة ٨ صباحاً الى الساعة ٧ مساءًً</p>
                            <p>شتاءً&nbsp;من الساعة ٨ صباحاً الى الساعة ٦ مساءًً</p>
                        </div>
                        </div>
                        <hr className={'red-hr vertical-separator'}/>
                        <p><span class="info-title">الزيارات المنزلية</span>0993863037</p>
                    </div>
                    <hr className={'red-hr vertical-separator'}/>
                    <div><a href="https://www.facebook.com/dr.omar.habash/"><i className="fab fa-facebook-square"/>  صفحتنا على فيس بوك
                        </a></div>
                </div>
            </div>
        </div>
    </div>
);

export default ContactPage;