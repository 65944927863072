export default {
    mainMenu: {
        home: 'الصفحة الرئيسية',
        about: 'عن المختبرات',
        lab_results: 'نتائج التحاليل',
        patientLabResults: "نتائج التحاليل للمرضى",
        results:'نتائج التحاليل',
        labsLabResults:'نتائج التحاليل للمخابر',
        doctorLabResults:'نتائج التحاليل للأطباء',
        contactUs:'اتصل بنا',
        helpFullInfo:'معلومات مفيدة',
        info:{
            beforeAndAfter:'التوصيات الطبية قبل و أثناء و بعد إجراء تحليل الدم ',
            noneBlood:'التوصيات الطبية لإجراء التحاليل المخبرية الغير دموية',
            fasting:'ساعات الصيام قبل تحليل الدم الشامل',
            foodBeforeAnalysis:'مواعيد تناول الطعام قبل التحليل',
            fastingFAQ:'أسئلة شائعة عن الصيام قبل التحليل',
            gettingReady:'كيفية الاستعداد لتحليل الدم'
        }



    },
    labels: {
        patientName: 'اسم المريض',
        password: 'رقم التعريف',
        patientLabResults:'نتائج التحاليل',
        comingSoon:'الخدمة ستكون متاحة قريباً',
        getResults:'اظهار النتائج',
        search:'بحث....',
        mainMenu:'القائمة الرئيسية',
        userOptions:'خيارات الموقع',
        insurance:'التأمين الصحي',
        insuranceInfo:'مختبرات حبش للتحاليل الطبية متعاقدة مع جميع شركات التأمين الصحي في سوريا ',
        more:'المزيد',
        homeServices:'الزيارات المنزلية'
    }

}