import React, {Component} from 'react';
import './App.css';
import {Header,MainPanel,Editor} from './Components';
import {Route} from "react-router";
import {HashRouter} from 'react-router-dom';



class App extends Component {
    render() {
        /*  if (document.location.hash === '#editor') {
              return <Editor config={{
                  toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                  heading: {
                      options: [
                          {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                          {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
                          {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'}
                      ]
                  }
              }}/>
          }
  */
        return (
            <HashRouter>
                <div className="App">
                    <div className={"main-area"}>
                        <header className="App-header">
                            <Header/>
                        </header>
                        {/*<Content/>*/}
                        <Route exact path="/editor" component={Editor}/>
                        <Route exact path="/" component={() => <MainPanel page="home"/>}/>
                        <Route exact path="/contact" component={() => <MainPanel page="contact"/>}/>
                        <Route exact path="/info" component={() => <MainPanel page="info"/>}/>
                        <Route exact path="/nbTests" component={() => <MainPanel page="nbTests"/>}/>
                        <Route exact path="/fasting" component={() => <MainPanel page="fasting"/>}/>
                        <Route exact path="/foodBeforeTest" component={() => <MainPanel page="foodBeforeTest"/>}/>
                        <Route exact path="/fastingFAQs" component={() => <MainPanel page="fastingFAQs"/>}/>
                        <Route exact path="/gettingReady" component={() => <MainPanel page="gettingReady"/>}/>
                        <Route exact path="/results" component={() => <MainPanel page="labResults"/>}/>
                        <Route exact path="/resuls-login" component={() => <MainPanel page="labResults"/>}/>

                        <div className={"page-footer info"}>
                            <table border={0} cellSpacing={0} cellPadding={'3px 0'}>
                                <tr>
                                    <td colSpan={2}>
                                        <div className={"title"}>مختبرات حبش للتحاليل الطبية</div>
                                        <div className="facebook-link"><a
                                            href="https://www.facebook.com/dr.omar.habash/"><i
                                            className="fab fa-facebook-square"/> صفحتنا على فيس بوك
                                        </a></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width={'50%'} className={"info-label"}>
                                        الفرع 1
                                    </td>
                                    <td width={'50%'}>
                                        الفرع 2
                                    </td>
                                </tr>
                                <tr>
                                    <td>جسر فيكتوريا - مجمع فيكتوريا  التجاري - فوق المصرف التجاري رقم 9</td>
                                    <td>الجسر الأبيض - أول نزلة الطلياني - جادة حبوباتي</td>
                                </tr>
                                <tr>
                                    <td> <span className={"info-label"}><i className="fas fa-phone"></i> </span>
                                        <span className={"blue-label important"}>2238766-22960290 </span></td>
                                    <td><span className={"info-label"}><i className="fas fa-phone"></i> </span>
                                        <span className={"blue-label important"}>3348664 - 3348665 </span></td>
                                </tr>
                                <tr>
                                    <td><span className={"info-label"}><i className="fas fa-mobile-alt"></i>  </span><span className={"blue-label important"}>0993767431</span></td>
                                    <td><span className={"info-label"}><i className="fas fa-mobile-alt"></i>  </span><span className={"blue-label important"}>0993863037</span></td>
                                </tr>
                                <tr>
                                    <td><span className={"info-label"}><i className="far fa-envelope"></i> </span><span className={"blue-label important"}><a href="mailto:support@habashlabs.com<">support@habashlabs.com</a></span></td>
                                    <td><span className={"info-label"}><i className="far fa-envelope"></i> </span><span className={"blue-label important"}><a href="mailto:dr_omarh_lab@outlook.com">dr_omarh_lab@outlook.com</a></span></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </HashRouter>

        );
    }
}

export default App;
