import React, {Component} from 'react';
import lang from "../../resources/settings/language";
import "./styles.css"
import "./dropdown.css"
import '@fortawesome/fontawesome-free/css/all.css'
import insurancePage from '../../resources/data/insurance';
import MenuItem from "./MenuItem";

let insuranceCompanies = {__html: insurancePage.details[1].details};
let homeService = {__html: insurancePage.details[2].homeService};

class RightBanner extends Component {

    constructor(props) {
        super(props);
        let activePage=document.location.hash.substring(2);

        this.state = {showInfoSubmenu: false, activePage: activePage};
        this.showSubMenu = this.showSubMenu.bind(this);
        this.hideSubMenu = this.hideSubMenu.bind(this);
        this.setActivePage = this.setActivePage.bind(this);
        this.loadPage =  this.loadPage.bind(this);
    }

    setActivePage(page){
    }
    loadPage(page){
        document.location='#results';
    }

    showSubMenu(menuId){
        this.setState({showInfoSubmenu:true})
    }
    hideSubMenu(menuId){

        this.setState({showInfoSubmenu:false})
    }
    render() {
        return (
            <div style={{width: '270px'}}>
                <div>
                    {/*<div className="search">
                            <input type={"text"} className={"keywords"} placeholder=" " />
                    </div>*/}
                    <div className={"sb-menu"}>
                        <ul className={"sb-menu"}>
                            <MenuItem  link='' active={this.state.activePage=== 'home'} faIcon={'fa-home'} text={lang.mainMenu.home}/>
                            <li className={"rb-menu-item dropdown"} onMouseOver={()=>this.showSubMenu()} onMouseOut={()=>this.hideSubMenu()}>
                                <div className={"dropdown-opener"}>
                                    <i className="fas fa-poll-h"/>
                                    {lang.mainMenu.results}
                                    <i className={this.state.showInfoSubmenu?'fas fa-caret-down menu-trigger icon-arrow open':'fas fa-caret-down menu-trigger icon-arrow close'}/>
                                </div>
                                <ul id="results-sub-menu" className={this.state.showInfoSubmenu ? "dropdown-menu show" : 'dropdown-menu hide'}>
                                    <li className={"rb-menu-item"} onClick={()=>this.loadPage('labResults')}><span><i className={"fas fa-vials"}/>{lang.mainMenu.patientLabResults}</span></li>
                                    <li className={"rb-menu-item"} onClick={()=>this.loadPage('labResults')}><span><i className={"fas fa-vials"}/>{lang.mainMenu.labsLabResults}</span></li>
                                    <li className={"rb-menu-item"} onClick={()=>this.loadPage('labResults')}><span><i className="fas fa-user-md"/>{lang.mainMenu.doctorLabResults}</span></li>
                                </ul>
                            </li>
                            <MenuItem active={this.state.activePage === 'info'} link="info"
                                      text={lang.mainMenu.info.beforeAndAfter} faIcon={'fa-receipt'}
                            />
                            <MenuItem active={this.state.activePage === 'nbTests'} link={'nbTests'}
                                      text={lang.mainMenu.info.noneBlood} faIcon={"fa-receipt"}
                            />
                            <MenuItem active={this.state.activePage === 'fasting'} link={'fasting'}
                                      text={lang.mainMenu.info.fasting} faIcon={"fa-receipt"}
                            />
                            <MenuItem active={this.state.activePage === 'foodBeforeTest'} link={'foodBeforeTest'}
                                      text={lang.mainMenu.info.foodBeforeAnalysis} faIcon={"fa-receipt"}
                            />

                            <MenuItem active={this.state.activePage === 'fastingFAQs'} link={'fastingFAQs'}
                                      text={lang.mainMenu.info.fastingFAQ} faIcon={'fa-receipt'}/>
                            <MenuItem active={this.state.activePage === 'gettingReady'} link={'gettingReady'}
                                      faIcon={"fa-receipt"} text={lang.mainMenu.info.gettingReady}/>
                            <MenuItem active={this.state.activePage === 'contactUs'} faIcon="fa-file-signature"
                                      link={'contact'}
                                      text={lang.mainMenu.contactUs}/>
                        </ul>
                        <div className={'right-info-banner'}>
                            <div className={"title important"}>{lang.labels.homeServices}</div>
                            <div className={"info-text"} dangerouslySetInnerHTML={homeService}/>
                            <div className={'info-separator'}/>
                            <div className={"title important"}>{lang.labels.insurance}</div>
                            <div className={"info-text"}>{lang.labels.insuranceInfo}</div>
                            <div dangerouslySetInnerHTML={insuranceCompanies}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RightBanner;

