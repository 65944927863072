import React, { Component } from 'react';
import CKEditor from 'ckeditor4-react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Editor extends Component {
    render() {
        return (
            <div className="App cke_rtl" style={{height:'1000px'}}>
                <CKEditor
                    editor={ ClassicEditor }
                    data="<p>Hello from CKEditor 5!</p>"
                    className={"cke_rtl"}
                    style={{height:'900px'}}


                    config={ {
                        direction: 'rtl',
                        contentsLangDirection:'rtl',
                        autoGrow_minHeight:900
                    }}
                        onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = event.editor.getData()
                        console.log(  data  );
                    } }
                />
            </div>
        );
    }
}

export default Editor;