import lang from "../../resources/settings/language";

import React, {Component} from 'react';
import {MainMenu} from "../index";
import './styles.css';


class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className={"main-header"}/>
                <MainMenu/>
                <div className={"lab-logo"}/>
            </div>
        )
    }
}

export default Header;
