import React, {Component} from 'react';

const MenuItem = props => (

    <li className={props.active?'rb-menu-item active-menu-item':'rb-menu-item'} onClick={()=>document.location='#' + props.link} >
        <span href={'#' + props.link}><i className={"fas " + props.faIcon}/>
        <span>{props.text}</span></span>
    </li>

);

export default MenuItem;