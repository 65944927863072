import React, {Component} from 'react';
import lang from "../../resources/settings/language";
import "./styles.css"

class ResultsLogin extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({context: nextContext.context})
    }

    render() {
        return (
            <div className={'login-form'}>
                <div class="page-title">{lang.labels.patientLabResults}</div>
                <br/><br/>
                <div className="page-sub-title"><strong>{lang.labels.comingSoon}.</strong></div>
            </div>
        )
    }
}

export default ResultsLogin;
